import axiosIns from '@/libs/axios'
import moment from 'moment'
import { getField, updateField } from 'vuex-map-fields'
import {
  axiosKompack, hideAccount, today, formatYmd, last30, axiosKomship, axiosKomtim, kompackDate,
} from '../helpers'

export default {
  namespaced: true,
  state: {
    saldo: 0,
    saldoPending: 0,
    rekenings: [],
    table: {
      perPage: 5,
      currentPage: 1,
      totalRows: 0,
    },
    riwayatPenarikans: [],
    dateRange: {
      startDate: kompackDate,
      endDate: today,
    },
    nominalTopUp: '',
    rekeningSelected: '',
    nominal: '',
    pin: '',
    currentStep: 0,
  },
  getters: {
    getField,
    rekening(state) {
      const rekening = state.rekenings[0]
      if (!rekening) return {}
      return {
        ...rekening,
        noRek: hideAccount(rekening.noRek),
      }
    },
    rekenings(state) {
      return state.rekenings.map(rekening => ({
        ...rekening,
        noRek: hideAccount(rekening.noRek),
        rekNumber: rekening.noRek,
        label: `${rekening.bank} - ${hideAccount(rekening.noRek)} - A.N. ${rekening.nama}`,
      }))
    },
    rekTujuanOptions(state) {
      return state.rekenings.map(rekening => ({
        value: rekening.id,
        text: `${rekening.bank} - ${rekening.noRek}`,
      }))
    },
    rincianPenarikan(state) {
      return {
        ...state.rincianPenarikan,
        totalSaldo: state.saldo,
        sisaSaldo: state.saldo - state.rincianPenarikan.jumlahPenarikan,
      }
    },
    nominalTopUp(state) {
      return Number(state.nominalTopUp.replace(/[^0-9,-]+/g, ''))
    },
    nominal(state) {
      return Number(state.nominal.replace(/[^0-9,-]+/g, ''))
    },
    selectedRekTujuan(state) {
      return (
        state.rekenings.find(
          rekening => rekening.id === state.selectedRekTujuan,
        ) || {}
      )
    },
  },
  mutations: {
    updateField,
    UPDATE_BALANCE_SUMMARY(state, balanceSummary) {
      state.saldo = balanceSummary.balance
      state.saldoPending = balanceSummary.pending_balance
    },
    UPDATE_REKENINGS(state, bankAccounts) {
      state.rekenings = bankAccounts.map(account => ({
        id: account.id,
        nama: account.bank_owner_name,
        bank: account.bank_name,
        noRek: account.bank_no,
      }))
    },
    UPDATE_TOTAL_ROWS(state, totalRows) {
      state.table.totalRows = totalRows
    },
    UPDATE_CURRENT_PAGE(state, currentPage) {
      state.table.currentPage = currentPage
    },
    UPDATE_RIWAYAT_PENARIKAN(state, withdrawalRequest) {
      state.riwayatPenarikans = withdrawalRequest.map(request => ({
        id: request.id,
        tanggal: moment(new Date(request.created_at)).format('DD-MM-YYYY'),
        jam: moment(new Date(request.created_at)).format('HH:mm'),
        tujuan: {
          nama: request.bank_owner_name,
          bank: request.bank_name,
          noRek: request.bank_no,
        },
        status: request.status,
        jumlahPenarikan: request.nominal,
        user_id: request.user_id,
        pesan: request.notes,
      }))
    },
    UPDATE_NOMINAL(state, nominal) {
      state.nominalTopUp = nominal
    },
    UPDATE_PER_PAGE(state, perPage) {
      state.table.perPage = perPage
    },
    RESET_STORE(state) {
      state.saldo = 0
      state.saldoPending = 0
      state.rekenings = []
      state.table.perPage = 5
      state.table.currentPage = 1
      state.table.totalRows = 0
      state.riwayatPenarikans = []
      state.dateRange.startDate = kompackDate
      state.dateRange.endDate = today
      state.nominalTopUp = ''
      state.rekeningSelected = ''
      state.nominal = ''
      state.pin = ''
      state.currentStep = 0
    },
  },

  actions: {
    init({ dispatch }) {
      return new Promise((resolve, reject) => {
        dispatch('getBalanceSummary')
          .then(res => resolve(res))
          .catch(err => reject(err))
        dispatch('getBankAccount')
          .then(res => resolve(res))
          .catch(err => reject(err))
        dispatch('getWithdrawalRequest')
          .then(res => resolve(res))
          .catch(err => reject(err))
      })
    },
    getBalanceSummary({ commit, rootState }) {
      const mitraId = rootState.auth.userData.mitras.id
      return axiosKompack(mitraId)
        .get('v1/kompack/finance/balance')
        .then(response => {
          if (response.data.data) commit('UPDATE_BALANCE_SUMMARY', response.data.data)
        })
    },
    getBankAccount({ commit }) {
      return axiosKompack()
        .get('v1/kompack/setting/bank-account')
        .then(response => {
          commit('UPDATE_REKENINGS', response.data.data)
        })
    },
    getWithdrawalRequest({
      commit,
      rootState,
      state,
    }) {
      return axiosKompack()
        .get('v1/kompack/finance/withdrawal-history', {
          params: {
            page: state.table.currentPage,
            limit: state.table.perPage,
            start_date: formatYmd(state.dateRange.startDate),
            end_date: formatYmd(state.dateRange.endDate),
          },
        })
        .then(response => {
          const { data } = response.data
          commit('UPDATE_RIWAYAT_PENARIKAN', data.data)
          commit('UPDATE_TOTAL_ROWS', data.total)
        })
    },
    checkPossibilityWD({ commit, state }) {
      return axiosKompack()
        .get('v1/kompack/finance/withdrawal/check-possible-withdraw', {
          params: {
            withdrawal_request_nominal: 10000,
          },
        })
        .then(res => {
          const {
            balance,
            maximum_withdraw_nominal,
            withdraw_possibilites,
          } = res.data.data

          const anyQueue = state.riwayatPenarikans.filter(wd => (wd.status === 'requested' || wd.status === 'on_process'))

          /* eslint-disable camelcase */
          state.currentStep = withdraw_possibilites && anyQueue.length === 0 ? 2 : 1
        })
    },
    checkPin({ state, rootState }) {
      const mitraId = rootState.auth.userData.mitras.id
      return axiosKompack().get('v1/kompack/setting/confirm/pin', {
        params: {
          user_id: mitraId,
          pin: state.pin,
        },
      })
    },
    withdrawalRequest({ getters, state }) {
      return axiosKompack().post('v1/kompack/finance/withdrawal/store', {
        nominal: parseInt(state.nominal.replace(/[^0-9,-]+/g, ''), 10),
        bank_name: state.rekeningSelected.bank,
        bank_no: parseInt(state.rekeningSelected.rekNumber, 10),
        bank_owner_name: state.rekeningSelected.nama,
      })
    },
    async exportSaldo({ rootState, state }) {
      const response = await axiosKompack().get(
        'v1/kompack/finance/withdrawal-history/export',
        {
          responseType: 'blob',
          params: {
            start_date: formatYmd(state.dateRange.startDate),
            end_date: formatYmd(state.dateRange.endDate),
          },
        },
      )
      const fileName = 'Komerce_KmPoin_Withdrawal_Request.xlsx'
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(
          new Blob([response.data], {
            type:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          }),
          fileName,
        )
      } else {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          }),
        )
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
      }
    },
  },
}
