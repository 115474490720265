import Vue from 'vue'

// axios
import axios from 'axios'
import useJwt from '@core/auth/jwt/useJwt'

const { jwt } = useJwt(axios, {})
const token = jwt.getToken()

const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 12000,
  headers: {
    'Application-Name': 'Web Komerce',
  },
})

export const komshipAxiosIns = axios.create({
  headers: {
    Authorization: `Bearer ${token}`,
  },
  baseURL: process.env.VUE_APP_BASE_URL_KOMSHIP,
  timeout: 12000,
})

export const kompackAxiosIns = axios.create({
  headers: {
    Authorization: `Bearer ${token}`,
  },
  baseURL: process.env.VUE_APP_BASE_URL_KOMPACK,
  timeout: 12000,
})

export const komtimAxiosIns = axios.create({
  headers: {
    Authorization: `Bearer ${token}`,
  },
  baseURL: process.env.VUE_APP_BASE_URL_KOMTIM,
  timeout: 12000,
})

const kompackMockAPI = axios.create({
  headers: {
    Authorization: `Bearer ${token}`,
  },
  baseURL: process.env.VUE_APP_BASE_URL_MOCKAPI,
  timeout: 12000,
})

export const newAxiosIns = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_NEW,
  timeout: 12000,
  headers: {
    Authorization: `Bearer ${token}`,
  },
})

axiosIns.interceptors.response.use(
  response => response,
  error => {
    if (error?.response?.status === 403) {
      window.location = '/unauthenticated'
    }
    /* eslint-disable comma-dangle */
    return Promise.reject(error)
  }
)

komshipAxiosIns.interceptors.response.use(
  response => response,
  error => {
    if (error?.response?.status === 403) {
      window.location = '/unauthenticated'
    }
    /* eslint-disable comma-dangle */
    return Promise.reject(error)
  }
)

kompackAxiosIns.interceptors.response.use(
  response => response,
  error => {
    if (error?.response?.status === 403) {
      window.location = '/unauthenticated'
    }
    /* eslint-disable comma-dangle */
    return Promise.reject(error)
  }
)

komtimAxiosIns.interceptors.response.use(
  response => response,
  error => {
    if (error?.response?.status === 403) {
      window.location = '/unauthenticated'
    }
    /* eslint-disable comma-dangle */
    return Promise.reject(error)
  }
)

kompackMockAPI.interceptors.response.use(
  response => response,
  error => {
    if (error?.response?.status === 403) {
      window.location = '/unauthenticated'
    }
    /* eslint-disable comma-dangle */
    return Promise.reject(error)
  }
)

newAxiosIns.interceptors.response.use(
  response => response,
  error => {
    if (error?.response?.status === 403) {
      window.location = '/unauthenticated'
    }
    /* eslint-disable comma-dangle */
    return Promise.reject(error)
  }
)

Vue.prototype.$http = axiosIns
Vue.prototype.$http_komship = komshipAxiosIns
Vue.prototype.$http_kompack = kompackAxiosIns
Vue.prototype.$http_komtim = komtimAxiosIns
Vue.prototype.$http_mockapi = kompackMockAPI
Vue.prototype.$http_new = newAxiosIns

export default axiosIns
