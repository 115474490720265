import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from '@/auth/utils'
import deliverConstraints from './routes/komship/deliver-constraints'
import pages from './routes/pages'
import setting from './routes/kompack/settings'
import gudang from './routes/kompack/gudang'
import monitoringRetur from './routes/komship/monitoring-retur'
import keuangan from './routes/kompack/keuangan'
import outbound from './routes/kompack/outbound'
import monitoringPickup from './routes/komship/monitoring-pickup'
import dashboard from './routes/komship/dashboard'
import dashboardWarehouse from './routes/kompack/dashboard'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...pages,
    ...deliverConstraints,
    ...setting,
    ...gudang,
    ...keuangan,
    ...monitoringRetur,
    ...outbound,
    ...monitoringPickup,
    ...dashboard,
    ...dashboardWarehouse,
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (to.name === 'unauthenticated' || to.name === 'error-404') {
    return next()
  }

  if (!canNavigate(to) && !isLoggedIn) {
    return next({ name: 'auth-login' })
  }

  if (
    // eslint-disable-next-line operator-linebreak
    (to.meta.redirectIfLoggedIn && isLoggedIn) ||
    (!canNavigate(to) && isLoggedIn && !to.meta.preventRedirect)
  ) {
    const userData = getUserData()
    next(
      getHomeRouteForLoggedInUser(
        // eslint-disable-next-line comma-dangle
        userData ? userData.mitras.mitra_type.toUpperCase() : null
        // eslint-disable-next-line comma-dangle
      )
    )
  }

  return next()
})

export default router
