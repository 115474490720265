import useJwt from '@/auth/jwt/useJwt'
import secureLocalStorage from '@/libs/secureLocalstorage'

// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return (
    secureLocalStorage.get('userData')
    && secureLocalStorage.get(useJwt.jwtConfig.storageTokenKeyName)
  )
}

export const getUserData = () => secureLocalStorage.get('userData')

export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'EKSPEDISI') return '/dashboard'
  if (userRole === 'WAREHOUSE') return '/dashboard-warehouse'

  return { name: 'auth-login' }
}
